import * as React from "react"

import Footer from "./footer"
import Header from "./header"
import HeaderMobile from "./headerMobile"
import ScrollUpButton from "./scrollUpButton"

const Layout = ({ children, pathName }) => {
  let isVisibleHeader = "hidden"

  if (pathName != `/`) {
    isVisibleHeader = "block"
  }

  return (
    <div className="bg-base-main">
      <div className={isVisibleHeader}>
        <HeaderMobile />
      </div>
      <div className={isVisibleHeader}>
        <Header />
      </div>
      <main>{children}</main>
      <ScrollUpButton />
      <Footer />
    </div>
  )
}

export default Layout
