import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import logo_flag from "../images/logo_flag.png"

const Header = () => {

    return (
        <header id="header">
            <div className="md:hidden w-full flex items-center justify-center px-8 text-base-dark bg-secondry-main">
                <Link to={`/`} className="text-sub-key hover:opacity-60 px-1 py-4 text-xs font-bold">
                    {/* <StaticImage src="../svg/logo_flag.svg" alt="" width={240} /> */}
                    <img src={logo_flag} width={120} />
                </Link>
            </div>
        </header>
    )
}

export default Header
