import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import button_scroll_up from "../svg/button_scroll_up.svg"

const ScrollUpButton = () => {

  const returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  };

  return (
    <div className="hidden md:block relative">
      <button onClick={returnTop} className="absolute -bottom-24 right-8 hover:opacity-60">
        <img src={button_scroll_up} alt="" width={140} />
      </button>
    </div>
  );
}

export default ScrollUpButton;
